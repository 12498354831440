import React, { useEffect, useRef, useState } from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};

// eslint-disable-next-line react/prop-types
export const ScalableText: React.FC<Props> = ({ className, children }) => {
  const textRef = useRef(null);
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    if (textRef?.current === null) {
      return;
    }
    // @ts-ignore
    if (typeof textRef.current.getBBox === 'function') {
      // @ts-ignore
      const box = textRef.current.getBBox();
      setSize([box.width, box.height]);
    }
  }, [textRef]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      width="100%"
      height="100%"
      viewBox={`0 0 ${size[0]} ${size[1]}`}
    >
      <text x="0" y="30" fontSize="30px" ref={textRef} className={className}>
        {children}
      </text>
    </svg>
  );
};

ScalableText.defaultProps = {
  className: undefined,
};
