import { makeStyles } from '@material-ui/core';
import { NetworkErrorMessage } from 'api/fetchSortationData';
import { ScalableText } from '../ScalableText';

interface ScanResponseProps {
  success: boolean;
  labelNumber: string;
  // eslint-disable-next-line react/require-default-props
  error?: string;
  // eslint-disable-next-line react/require-default-props
  messageOverride?: string;
}

interface StyleProps {
  success: boolean;
}

const useStyles = makeStyles(() => ({
  container: (props: StyleProps) => ({
    // padding: '1em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: props.success ? '#EDFAF1' : '#FDF3F2',
    border: `1px solid ${props.success ? '#009D31' : '#E02D1B'}`,
    borderRadius: 4,
  }),
  responseTypeMessage: (props: StyleProps) => ({
    color: props.success ? '#009D31' : '#E02D1B',
  }),
  message: (props: StyleProps) => ({
    fill: props.success ? '#009D31' : '#E02D1B',
  }),
  largeText: {
    fontWeight: 500,
    fontSize: '5.5vw',
    margin: 0,
  },
  responseContainer: {
    padding: '2em',
  },
  icon: {
    padding: '1em',
  },
  subHeading: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '19px',
    margin: 0,
    color: '#484A50',
    padding: 18,
  },
}));

const ScanResponse = ({
  success,
  labelNumber,
  error = undefined,
  messageOverride = undefined,
}: ScanResponseProps) => {
  const classes = useStyles({ success });

  let message =
    messageOverride || (success ? 'SCAN SUCCESSFUL' : 'SCAN FAILED');
  const icon = success ? 'tick.svg' : 'cross.svg';

  if (!success) {
    if (error === 'GoPeople grouping has not been run') {
      message = 'DRIVER NOT YET ALLOCATED';
    } else if (error === NetworkErrorMessage) {
      message = error;
    } else if (error === 'holding') {
      message = 'On Hold';
    } else if (error === 'rts') {
      message = 'RTS';
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.responseContainer}>
        <p className={`${classes.responseTypeMessage} ${classes.largeText}`}>
          <ScalableText className={classes.message}>{message}</ScalableText>
        </p>
        <img className={classes.icon} src={icon} alt={message} />
      </div>
      <p className={classes.largeText}>
        <ScalableText>{labelNumber}</ScalableText>
      </p>
      <p className={classes.subHeading}>Label number</p>
    </div>
  );
};

export default ScanResponse;
