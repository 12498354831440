import {
  AppBar,
  Toolbar,
  makeStyles,
  Link,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from '@material-ui/core';
import { useAuthorization } from 'auth/AuthorizationProvider';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isCurrentWarehouse } from '../../util/warehouse';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#03A687',
  },
  titleStyle: {
    fontSize: 24,
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  title: {
    width: '100%',
    padding: '0 10px',
  },
}));

interface AppBarProps {
  warehousesList: string[];
  handleBOL: (to: string) => void;
}

export default function CustomAppBar({
  warehousesList,
  handleBOL,
}: AppBarProps) {
  const classes = useStyles();
  const { sessionExpired, currentWarehouse } = useAuthorization();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleOnLogOut = () => {
    sessionExpired();
    navigate(`/`);
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const warehousesBOLs = (warehousesList || []).map(wh => {
    if (isCurrentWarehouse(wh, currentWarehouse)) return <div key={wh} />;
    return (
      <MenuItem
        key={wh}
        onClick={() => {
          handleBOL(wh);
        }}
      >
        Bill of Lading to {wh}
      </MenuItem>
    );
  });

  return (
    <AppBar
      position="static"
      className={classes.root}
      data-testid="custom-app-bar"
    >
      <Toolbar>
        <Link href="/">
          <img src="logo.svg" alt="Lyne Logo" />
        </Link>
        <Typography variant="h6" className={classes.title}>
          {currentWarehouse}
        </Typography>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {warehousesBOLs}
          <MenuItem
            onClick={() => {
              navigate(`/manifests`);
            }}
          >
            Manifests
          </MenuItem>
          <MenuItem onClick={handleOnLogOut}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
