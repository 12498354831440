import axios from 'axios';
import { endpoint } from '../constants/endpoints';
import { NetworkErrorMessage } from './fetchSortationData';
import { ErrorResponse } from './shared';

export type ListManifestsParams = {
  status: string;
  depotCode: string;
  authorizationToken: string;
};

export type Manifest = {
  manifestNumber: string;
  createdAt: string;
  depotCode: string;
  courierCode: string;
  closedAt?: string;
  contains: {
    pallets: number;
    shipments: number;
  };
};

const allowedOrigin =
  process.env.REACT_APP_ORIGIN_HEADER || 'https://gateway.lynedirect.com/sort';

const buildHeaders = (authorizationToken: string) => ({
  origin: allowedOrigin,
  Authorization: `Bearer ${authorizationToken}`,
});

export const closeManifest = async (
  manifestNumber: string,
  authorizationToken: string
): Promise<boolean> => {
  const headers = buildHeaders(authorizationToken);
  try {
    const result = await axios.put(
      `${endpoint.MANIFESTS}-close`,
      {},
      {
        headers,
        params: {
          manifestNumber,
        },
      }
    );
    return result.status === 200 && result.data.isClosed;
  } catch (error) {
    console.dir(error);
    if (error.message !== 'Network Error') {
      throw Error(JSON.stringify(error.response));
    } else {
      throw Error(JSON.stringify({ data: { message: 'Unknown error' } }));
    }
  }
};

export const listManifests = async ({
  authorizationToken,
  status,
  depotCode,
}: ListManifestsParams): Promise<Manifest[] | ErrorResponse> => {
  const headers = buildHeaders(authorizationToken);
  try {
    const result = await axios.get(`${endpoint.MANIFESTS}s`, {
      headers,
      params: {
        status,
        depotCode,
      },
    });
    return result.status === 200 && result.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        message: error.response.data.message,
        statusCode: error.response.status,
      };
    }
    return { error: true, message: NetworkErrorMessage };
  }
};

export type ListManifests = typeof listManifests;
export type CloseManifest = typeof closeManifest;
