import { makeStyles, Avatar } from '@material-ui/core';
import Label from './Label';
import { ScalableText } from '../ScalableText';

interface SortationDataCellProps {
  circle: boolean;
  title: string | number | undefined;
  subTitle: string;
  dataTestId: string;
}
const useStyles = makeStyles(() => ({
  dataContainer: {
    margin: 0,
    height: 182,
    width: 182,
  },
  data: {
    height: '150px',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  circleData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 14,
    height: '150px',
  },
  circleHighlight: {
    margin: 0,
    height: '150px',
    width: '150px',
    backgroundColor: '#03A687',
    borderRadius: '50%',
  },
  circleText: {
    margin: 0,
    fontSize: '150px',
    lineHeight: '150px',

    fontWeight: 500,
    color: 'white',
    fontFamily: 'DM Sans',
  },
}));

const SortationDataCell = ({
  circle,
  title,
  subTitle,
  dataTestId,
}: SortationDataCellProps) => {
  const classes = useStyles();

  return (
    <div>
      {circle ? (
        <div className={classes.circleData}>
          <Avatar className={classes.circleHighlight}>
            <p
              className={classes.circleText}
              data-testid={`${dataTestId}-circle`}
            >
              {title}
            </p>
          </Avatar>
        </div>
      ) : (
        <div>
          <p className={classes.data} data-testid={dataTestId}>
            <ScalableText>{title}</ScalableText>
          </p>
        </div>
      )}
      <Label text={subTitle} />
    </div>
  );
};

export default SortationDataCell;
