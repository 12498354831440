export const endpoint = {
  SORT:
    process.env.REACT_APP_SORT_ENDPOINT || 'http://localhost:3000/local/sort',
  LOGIN:
    process.env.REACT_APP_LOGIN_ENDPOINT ||
    'http://localhost:3000/local/sortation/login',
  BOL:
    process.env.REACT_APP_BOL_ENDPOINT ||
    'http://localhost:3000/local/bill-of-lading',
  PALLETS:
    process.env.REACT_APP_PALLETS_ENDPOINT ||
    'http://localhost:3000/local/pallet-label',
  MANIFESTS:
    process.env.REACT_APP_MANIFESTS_ENDPOINT ||
    'http://localhost:3000/local/manifest',
};
