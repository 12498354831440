import {
  CircularProgress,
  makeStyles,
  Theme,
  Backdrop,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '28em',
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D8DAE0',
    borderRadius: 4,
    backgroundColor: 'white',
    opacity: 1,
  },
  responseContainer: {
    padding: '2em',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  loader: {
    color: '#03A687',
  },
}));

const ResultLoader = ({ hardBlock }: { hardBlock?: boolean }) => {
  const classes = useStyles();
  return (
    <>
      {hardBlock ? (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className={classes.container}>
          <div className={classes.responseContainer}>
            <CircularProgress className={classes.loader} />
          </div>
        </div>
      )}
    </>
  );
};

ResultLoader.defaultProps = {
  hardBlock: false,
};

export default ResultLoader;
