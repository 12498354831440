import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ZoneRun } from '../../types/ZoneRun';
import SortationDataCell from './SortationDataCell';
import PalletControl from './PalletControl';
import ManifestControl from './ManifestControl';

export interface SortationDisplayProps {
  labelNumber: string;
  zone?: string;
  courierCode?: string;
  sortCode?: string;
  zoneRun?: ZoneRun;
  pallet?: {
    labelNumber: string;
    isClosed: boolean;
  };
  manifest?: {
    labelNumber: string;
    closedAt?: string;
  };
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: '1px solid #D8DAE0',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  dataBox: {
    flexGrow: 1,
    padding: '1em',
  },
  accented: {
    animationName: '$color',
    animationDuration: '2s',
    animationIterationCount: '1',
  },
  header: {
    visibility: 'hidden',
    fontWeight: 500,
    fontSize: '5.5vw',
    margin: 0,
    // padding: '1em',
  },
  accentedHeader: {
    visibility: 'visible',
    color: '#009D31',
    animationName: '$textColor',
    animationDuration: '2s',
    animationIterationCount: '1',
  },
  error: {
    fill: '#E02D1B',
    textAlign: 'center',
  },
  '@keyframes color': {
    '0%': {
      backgroundColor: 'auto',
      border: '1px solid #009D31',
    },
    '20%': {
      backgroundColor: '#EDFAF1',
    },
    '100%': {
      backgroundColor: 'auto',
    },
  },
  '@keyframes textColor': {
    '0%': {
      opacity: '0%',
    },
    '20%': {
      opacity: '100%',
    },
    '100%': {
      opacity: '0%',
    },
  },
}));

const PalletSortationDisplay = (props: SortationDisplayProps) => {
  const classes = useStyles();
  const { courierCode, sortCode, pallet, manifest } = props;
  const [accented, setAccented] = useState<boolean>(true);

  useEffect(() => {
    const accentTimeout = setTimeout(() => setAccented(false), 2 * 1000);
    return () => {
      clearTimeout(accentTimeout);
    };
  }, []);

  let accentedClass = '';
  let accentedHeaderClass = '';
  if (accented) {
    accentedClass = classes.accented;
    accentedHeaderClass = classes.accentedHeader;
  }

  return (
    <div className={`${classes.container} ${accentedClass}`}>
      <h1 className={`${classes.header} ${accentedHeaderClass}`}>
        PALLET SCAN SUCCESSFUL
      </h1>
      <Grid container data-testid="sortation-display">
        <Grid item className={classes.dataBox} md={6}>
          <SortationDataCell
            circle={false}
            title={sortCode}
            subTitle="Sort code"
            dataTestId="zone-display"
          />
        </Grid>
        <Grid item className={classes.dataBox} md={6}>
          <SortationDataCell
            circle={false}
            title={courierCode}
            subTitle="Courier code"
            dataTestId="courier-code-display"
          />
        </Grid>
        {pallet && <PalletControl pallet={pallet} />}
        {manifest && <ManifestControl manifest={manifest} />}
      </Grid>
    </div>
  );
};

export default PalletSortationDisplay;
