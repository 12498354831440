import axios from 'axios';
import { endpoint } from 'constants/endpoints';
import { DateTime } from 'luxon';
import { ZoneRun } from 'types/ZoneRun';
import { ErrorResponse } from './shared';

export const NetworkErrorMessage = 'Unable to connect to server';

const buildVersion = process.env.REACT_APP_BUILD_VERSION || '2021-01-01T00:00';

const buildHeaders = (authorizationToken: string) => ({
  Authorization: `Bearer ${authorizationToken}`,
  'X-Build-Version': buildVersion,
});
export type SuccessResponse = {
  shipmentUpdatedAt: Date;
  zone: string;
  courierCode: string;
  id: string;
  sortCode?: string;
  depotCode?: string;
  zoneRunUpdatedAt?: Date;
  zoneRun?: ZoneRun;
  overlabelledWith?: string;
  labelURL?: string;
  shipmentActivity: string;
  nextScanDisplay?: string;
  pallet?: {
    labelPDFUrl: string;
    labelNumber: string;
    isClosed: boolean;
    closedAt?: string;
  };
  manifest?: {
    labelNumber: string;
    closedAt?: string;
  };
  type: 'shipment' | 'pallet';
};

export type StatusResponse = {
  message: string;
};

export const fetchSortationData = async (
  selectedSortStatus: string,
  labelNumber: string,
  authorizationToken: string
): Promise<SuccessResponse | StatusResponse | ErrorResponse> => {
  const headers = buildHeaders(authorizationToken);
  try {
    const result = await axios.post(
      `${endpoint.SORT}`,
      {
        status: selectedSortStatus,
        labelNumber,
        scannedAt: DateTime.local().toString(),
      },
      { headers }
    );
    return result.data as SuccessResponse;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        message: error.response.data.message,
        statusCode: error.response.status,
      };
    }
    return { error: true, message: NetworkErrorMessage };
  }
};
