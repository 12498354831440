import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { CheckCircle } from '@material-ui/icons';
import { ScalableText } from '../ScalableText';
import { closeManifest } from '../../api/manifests';
import { useAuthorization } from '../../auth/AuthorizationProvider';
import Label from './Label';

export interface ManifestProps {
  manifest: {
    labelNumber: string;
    closedAt?: string;
  };
}

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonIcon: {
    paddingRight: '0.25em',
  },
}));

const ManifestControl = ({ manifest }: ManifestProps) => {
  const classes = useStyles();
  const { authorizationToken, sessionExpired } = useAuthorization();

  const [isClosed, setIsClosed] = useState<boolean>(!!manifest.closedAt);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirm: boolean) => {
    confirm && (await handleManifestClose());
    setDialogOpen(false);
  };

  const handleManifestClose = async () => {
    if (manifest === undefined) {
      return;
    }
    try {
      setIsClosed(
        await closeManifest(manifest.labelNumber, authorizationToken)
      );
    } catch (error) {
      sessionExpired();
      window.location.reload();
    }
  };

  const closedAt = manifest.closedAt
    ? new Date(manifest.closedAt).toLocaleString()
    : undefined;

  return (
    <>
      <Grid item xs={12}>
        <ScalableText>{manifest.labelNumber}</ScalableText>
      </Grid>
      <Grid item xs={12}>
        <Label text="Manifest number" />
      </Grid>

      <Grid item xs={12}>
        {closedAt && <Label text={`Closed at: ${closedAt}`} />}
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          size="large"
        >
          <>
            <Button onClick={handleDialogOpen} disabled={isClosed}>
              <CheckCircle className={classes.buttonIcon} />
              {isClosed ? 'Manifest Closed' : 'Close Manifest'}
            </Button>
          </>
        </ButtonGroup>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Close Manifest</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close manifest {manifest.labelNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManifestControl;
