/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { endpoint } from 'constants/endpoints';

const headers = {};

export const login = async (
  username: string,
  password: string
): Promise<any | Error> => {
  try {
    const result = await axios.post(
      `${endpoint.LOGIN}`,
      {
        username,
        password,
      },
      { headers }
    );
    return result.data;
  } catch (error) {
    if (error.response) {
      throw Error(error.response.data.message);
    } else {
      throw Error('Network Error');
    }
  }
};
