// eslint-disable-next-line max-len
// import { buildHeaders } from './headers';
import { endpoint } from '../constants/endpoints';

export const fetchBOL = async (
  fromWarehouse: string,
  toWarehouse: string,
  ts: Date,
  authorizationToken: string
): Promise<void | Error> => {
  const headers = new Headers({
    Authorization: `Bearer ${authorizationToken}`,
    Accept: 'application/pdf',
  });

  const queryParams = new URLSearchParams({
    date: ts.toISOString(),
    originWarehouse: fromWarehouse,
    destinationWarehouse: toWarehouse,
  });

  return fetch(`${endpoint.BOL}?${queryParams}`, {
    method: 'GET',
    headers,
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fromWarehouse}-${toWarehouse}-${ts.toISOString()}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      // @ts-ignore
      link.parentNode.removeChild(link);
    });
};
