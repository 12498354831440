import { makeStyles, Button } from '@material-ui/core';
import { ScalableText } from '../ScalableText';

interface OverlabelledDisplayProps {
  labelNumber: string;
  overlabelledWith: string;
  labelURL: string;
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#FDF3F2',
    border: '1px solid #E02D1B',
    borderRadius: 4,
  },
  responseTypeMessage: {
    color: '#E02D1B',
    fill: '#E02D1B',
  },
  largeText: {
    fontWeight: 500,
    fontSize: '5.5vw',
    margin: 0,
  },
  responseContainer: {
    padding: '2em',
  },
  icon: {
    padding: '1em',
  },
  subHeading: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '19px',
    margin: 0,
    color: '#484A50',
    padding: 18,
  },
  downloadLabelBtn: {
    margin: '1em',
    color: '#FFF',
  },
}));

const OverlabelledDisplay = ({
  labelNumber,
  overlabelledWith,
  labelURL,
}: OverlabelledDisplayProps) => {
  const classes = useStyles();
  const message = 'Overlabelling required';
  const icon = 'cross.svg';

  return (
    <div className={classes.container}>
      <div className={classes.responseContainer}>
        <p className={`${classes.responseTypeMessage} ${classes.largeText}`}>
          <ScalableText>{message}</ScalableText>
        </p>
        <img className={classes.icon} src={icon} alt={message} />
        <br />
        <Button
          target="_blank"
          download={`${overlabelledWith}.pdf`}
          href={labelURL}
          className={`${classes.subHeading} ${classes.downloadLabelBtn}`}
          variant="contained"
          color="secondary"
        >
          Download new Label
        </Button>

        <p className={classes.subHeading}>Current label</p>
        <p className={classes.largeText}>
          <ScalableText>{labelNumber}</ScalableText>
        </p>

        <p className={classes.subHeading}>New label</p>
        <p className={classes.largeText}>
          <ScalableText>{overlabelledWith}</ScalableText>
        </p>
      </div>
    </div>
  );
};

export default OverlabelledDisplay;
