import { createContext, useContext, useState } from 'react';

interface AuthorizationContextI {
  isAuthenticated: boolean;
  handleJWTChange: (jwt: string) => void;
  sessionExpired: () => void;
  authorizationToken: string;
  currentWarehouse: string;
}

const AuthorizationContext = createContext({} as AuthorizationContextI);
const useAuthorization = () => useContext(AuthorizationContext);

interface JWTData {
  warehouse?: string;
}

const decodeJWTPayload = (token: string): JWTData => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as JWTData;
  } catch (e) {
    return {};
  }
};

const AuthorizationContextProvider = (props: any) => {
  const jwtToken = window.localStorage.getItem('lyne-sortation-token');

  const currentWarehouse: string =
    // eslint-disable-next-line react/destructuring-assignment
    props.currentWarehouse || decodeJWTPayload(jwtToken || '').warehouse || '';

  const [isAuthenticated, setIsAuthenticated] = useState(() => !!jwtToken);

  const handleJWTChange = (jwt: string) => {
    window.localStorage.setItem('lyne-sortation-token', jwt);
    setIsAuthenticated(true);
  };

  const sessionExpired = () => {
    window.localStorage.removeItem('lyne-sortation-token');
    setIsAuthenticated(false);
  };

  return (
    <AuthorizationContext.Provider
      value={{
        isAuthenticated,
        handleJWTChange,
        sessionExpired,
        authorizationToken: jwtToken || '',
        currentWarehouse,
      }}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </AuthorizationContext.Provider>
  );
};

export { AuthorizationContextProvider, useAuthorization, AuthorizationContext };
