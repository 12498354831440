import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ZoneRun } from '../../types/ZoneRun';
import SortationDataCell from './SortationDataCell';
import Label from './Label';
import { ScalableText } from '../ScalableText';
import PalletControl from './PalletControl';
import ManifestControl from './ManifestControl';

export interface SortationDisplayProps {
  labelNumber: string;
  zone?: string;
  courierCode?: string;
  sortCode?: string;
  zoneRun?: ZoneRun;
  pallet?: {
    labelNumber: string;
    isClosed: boolean;
  };
  manifest?: {
    labelNumber: string;
    closedAt?: string;
  };
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: '1px solid #D8DAE0',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  dataBox: {
    flexGrow: 1,
    padding: '1em',
  },
  accented: {
    animationName: '$color',
    animationDuration: '2s',
    animationIterationCount: '1',
  },
  header: {
    visibility: 'hidden',
    fontWeight: 500,
    fontSize: '5.5vw',
    margin: 0,
    // padding: '1em',
  },
  accentedHeader: {
    visibility: 'visible',
    color: '#009D31',
    animationName: '$textColor',
    animationDuration: '2s',
    animationIterationCount: '1',
  },
  error: {
    fill: '#E02D1B',
    textAlign: 'center',
  },
  '@keyframes color': {
    '0%': {
      backgroundColor: 'auto',
      border: '1px solid #009D31',
    },
    '20%': {
      backgroundColor: '#EDFAF1',
    },
    '100%': {
      backgroundColor: 'auto',
    },
  },
  '@keyframes textColor': {
    '0%': {
      opacity: '0%',
    },
    '20%': {
      opacity: '100%',
    },
    '100%': {
      opacity: '0%',
    },
  },
}));

const ShipmentSortationDisplay = (props: SortationDisplayProps) => {
  const classes = useStyles();
  const { courierCode, sortCode, zoneRun, labelNumber, pallet, manifest } =
    props;
  const [accented, setAccented] = useState<boolean>(true);

  useEffect(() => {
    const accentTimeout = setTimeout(() => setAccented(false), 2 * 1000);
    return () => {
      clearTimeout(accentTimeout);
    };
  }, []);

  let courierZone = sortCode;

  if (zoneRun) {
    courierZone = zoneRun.id;
  }

  let accentedClass = '';
  let accentedHeaderClass = '';
  if (accented) {
    accentedClass = classes.accented;
    accentedHeaderClass = classes.accentedHeader;
  }

  let showCourierZone = true;

  if (courierCode === 'GP' && !zoneRun) {
    showCourierZone = false; // courierZone for GP looks very similar to zoneRun numbers
  }

  const ffUseManifest = new URL(window.location.href).searchParams.get(
    'ffUseManifest'
  );

  return (
    <div className={`${classes.container} ${accentedClass}`}>
      <h1 className={`${classes.header} ${accentedHeaderClass}`}>
        SCAN SUCCESSFUL
      </h1>
      <Grid container data-testid="sortation-display">
        <Grid item className={classes.dataBox} md={zoneRun ? 4 : 6}>
          <SortationDataCell
            circle={false}
            title={courierCode}
            subTitle="Courier code"
            dataTestId="courier-code-display"
          />
        </Grid>
        {showCourierZone && (
          <Grid item className={classes.dataBox} md={zoneRun ? 4 : 6}>
            <SortationDataCell
              circle={false}
              title={courierZone}
              subTitle={zoneRun ? 'Run number' : 'Sort code'}
              dataTestId="zone-display"
            />
          </Grid>
        )}
        {zoneRun && (
          <Grid item className={classes.dataBox} md={zoneRun ? 4 : 6}>
            <SortationDataCell
              circle
              title={zoneRun.sequenceRun}
              subTitle="Job sequence"
              dataTestId="zone-run-display"
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          data-testid="label-number-display"
          className={classes.dataBox}
        >
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <ScalableText>{labelNumber}</ScalableText>
            </Grid>
            <Grid item xs={12}>
              <Label text="Label number" />
            </Grid>
          </Grid>
        </Grid>
        {pallet && <PalletControl pallet={pallet} />}
        {ffUseManifest && manifest && <ManifestControl manifest={manifest} />}
      </Grid>
    </div>
  );
};

export default ShipmentSortationDisplay;
