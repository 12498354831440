import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import {
  AuthorizationContextProvider,
  useAuthorization,
} from './auth/AuthorizationProvider';
import { BOLContext, fetchBOL } from './contexts/BOLContext';
import SortPage from './pages/sortPage/SortPage';
import ManifestsPage from './pages/manifests/ManifestsPage';
import LoginPage from './pages/login/LoginPage';
import { closeManifest, listManifests } from './api/manifests';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuthorization();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <BOLContext.Provider value={{ fetchBOL }}>
      <AuthorizationContextProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <>
              <Route
                path="/manifests"
                element={
                  <RequireAuth>
                    <ManifestsPage
                      listManifests={listManifests}
                      closeManifest={closeManifest}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <SortPage />
                  </RequireAuth>
                }
              />
            </>
            <Route path="*">no route</Route>
          </Routes>
        </Router>
      </AuthorizationContextProvider>
    </BOLContext.Provider>
  );
}

export default App;
