import { FunctionComponent } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Label from '../SortationDataDisplay/Label';

type TransitResponseProps = {
  depotCode: string;
  labelNumber: string;
};

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    borderRadius: 4,
  },
  label: {
    fontSize: 24,
    fontWeight: 200,
    margin: 0,
    padding: 0,
  },
  value: {
    margin: 0,
    padding: 0,
    fontSize: '5.5vw',
  },
  header: {},
  item: {
    padding: '1em',
    fontWeight: 600,
  },
  depot: {
    fontSize: '12vw',
  },
}));

// eslint-disable-next-line max-len
export const TransitResponse: FunctionComponent<TransitResponseProps> = ({
  depotCode,
  labelNumber,
}: TransitResponseProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={12} className={classes.item}>
        <p
          className={`${classes.value} ${classes.depot}`}
          data-testid="last-mile-depot"
        >
          {depotCode}
        </p>
        <Label text="Last mile depot" />
      </Grid>
      <Grid item xs={12} md={12} className={classes.item}>
        <p className={classes.value}>{labelNumber}</p>
        <Label text="Label number" />
      </Grid>
    </Grid>
  );
};
