import { useState, useRef, useEffect } from 'react';

import { Input, makeStyles } from '@material-ui/core';

interface LabelNumberInputProps {
  onFormSubmit: (event: any, labelNumber: string) => void;
  locked: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  inputContainer: {
    height: 54,
    paddingLeft: 24,
    paddingRight: 24,
  },
  inputStyle: {
    height: 54,
    paddingLeft: 6,
    width: '100%',
    border: '1px solid #D8DAE0',
    borderColor: '#D8DAE0',
    borderRadius: 6,
    boxSizing: 'border-box',
    fontSize: 24,
    fontWeight: 500,
    fontStyle: 'normal',
  },
  contentContainer: {
    marginTop: 24,
    paddingBottom: 24,
    textAlign: 'left',
    width: '90%',
    border: '1px solid #D8DAE0',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  labelStyles: {
    fontSize: 24,
    marginTop: 12,
    marginLeft: 24,
    marginBottom: 12,
    fontWeight: 500,
    color: '#333132',
    fontStyle: 'normal',
  },
}));

const hideKeyboard = (element: HTMLInputElement) => {
  setTimeout(() => {
    const newElement = element;
    newElement.readOnly = true;
    newElement.focus();
    setTimeout(() => {
      newElement.readOnly = false;
    });
  }, 500);
};

const LabelNumberInput = ({ onFormSubmit, locked }: LabelNumberInputProps) => {
  const classes = useStyles();
  const [label, setLabel] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onFormSubmit(e, label);
    setLabel('');
  };

  useEffect(() => {
    if (!locked && inputRef.current) {
      hideKeyboard(inputRef.current);
    }
  }, [locked]);

  const onBlur = () => inputRef.current && hideKeyboard(inputRef.current);

  return (
    <form
      data-testid="label-number-form"
      className={classes.container}
      onSubmit={e => handleSubmit(e)}
    >
      <div className={classes.contentContainer}>
        <h1 className={classes.labelStyles}>Label number</h1>
        <div className={classes.inputContainer}>
          <Input
            className={classes.inputStyle}
            id="label-number-input"
            type="text"
            autoComplete="off"
            autoFocus
            disableUnderline
            placeholder="Enter or scan to search"
            inputProps={{ 'data-testid': 'label-number-input' }}
            value={label}
            onChange={e => setLabel(e.target.value)}
            onBlur={onBlur}
            required
            disabled={locked}
            inputRef={inputRef}
          />
        </div>
      </div>
    </form>
  );
};

export default LabelNumberInput;
