import { makeStyles } from '@material-ui/core';

interface LabelProps {
  text: string;
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16',
    fontWeight: 400,
    lineHeight: '19px',
    margin: 0,
    color: '#333132',
    opacity: 0.65,
    padding: '0.25em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

const Label = ({ text }: LabelProps) => {
  const classes = useStyles();
  return <p className={classes.label}>{text}</p>;
};

export default Label;
