/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { makeStyles, Grid, Input, Button } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';
import { login } from 'api/login';
import { useAuthorization } from 'auth/AuthorizationProvider';
import { useNavigate } from 'react-router-dom';
import lyneBackground from './background.svg';

const loginPageStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  contentContainer: {
    display: 'flex',
    backgroundColor: 'white',
    width: '18em',
    height: '550px',
    border: '1px solid #D8DAE0',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
  logoStyle: {
    width: '54%',
    height: '27%',
    marginLeft: '7%',
    marginTop: '8%',
  },
  greetingStyle: {
    marginLeft: '7%',
    marginTop: '42px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '19px',
    padding: 0,
    marginBottom: 0,
  },
  formContainer: {
    width: '100%',
    marginTop: 0,
  },
  userNameLabelStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: '#333132',
    marginLeft: '7%',
    marginBottom: '1%',
    marginTop: '24px',
  },
  passwordLabelStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: '#333132',
    marginLeft: '7%',
    marginTop: '16px',
    marginBottom: '1%',
  },
  userFiledInputStyle: {
    border: '1px solid #D8DAE0',
    borderRadius: '2px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'DM Sans',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '-0.24px',
    color: '#363943',
    paddingLeft: '4%',
    marginLeft: '7%',
    marginTop: '1%',
    width: '15em',
    '&::placeholder': {
      color: '#9EA3B2',
    },
  },
  userFiledInputFocusedStyle: {
    border: '2px solid #03A687',
    borderRadius: '2px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'DM Sans',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '-0.24px',
    color: '#363943',
    paddingLeft: '4%',
    marginLeft: '7%',
    marginTop: '1%',
    width: '15em',
    '&::placeholder': {
      color: '#9EA3B2',
    },
  },
  buttonStyle: {
    borderColor: '#D8DAE0',
    width: '15em',
    marginLeft: '7%',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: 'white',
    marginTop: '16px',
    height: '45px',
    backgroundColor: '#03A687',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#03A687',
    },
  },
  disableButtonStyle: {
    borderColor: '#D8DAE0',
    width: '15em',
    marginLeft: '7%',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#707278',
    marginTop: '16px',
    height: '45px',
    textTransform: 'none',
    backgroundColor: '#D8DAE0',
    '&:hover': {
      backgroundColor: '#D8DAE0',
    },
  },
  erroMessage: {
    color: '#FF0000',
    height: '10px',
    marginLeft: '7%',
    marginTop: '8px',
  },
});

const GlobalStyle = createGlobalStyle`
  body {
    background: url(${lyneBackground}) no-repeat center center fixed;
    background-color: #FAFAFA !important;
    background-size: cover;
  }
`;

const initialState = {
  username: '',
  password: '',
};

export default function LoginPage() {
  const classes = loginPageStyles();
  const [loginCred, setLoginCred] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const { handleJWTChange } = useAuthorization();
  const navigate = useNavigate();

  const handleCredentialsChange = (stateKey: string, value: any): void => {
    setLoginCred({ ...loginCred, [stateKey]: value });
  };

  const handleLoginSubmission = async (e: any) => {
    e.preventDefault();
    try {
      const result = await login(loginCred.username, loginCred.password);
      handleJWTChange(result);
      navigate(`/`);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className={classes.container}>
      <GlobalStyle />
      <Grid container className={classes.contentContainer} direction="row">
        <Grid item className={classes.logoStyle}>
          <img src="colored_logo.svg" alt="Lyne direct Logo" />
        </Grid>
        <form data-testid="login-form" onSubmit={handleLoginSubmission}>
          <div className={classes.greetingStyle}>
            Please sign in to continue
          </div>
          <div className={classes.userNameLabelStyle}>Username</div>
          <Input
            className={classes.userFiledInputStyle}
            classes={{ focused: classes.userFiledInputFocusedStyle }}
            inputProps={{ 'data-testid': 'username-input' }}
            type="text"
            autoFocus
            disableUnderline
            placeholder="Enter username"
            onChange={e => handleCredentialsChange('username', e.target.value)}
          />
          <div className={classes.passwordLabelStyle}>Password</div>
          <Input
            className={classes.userFiledInputStyle}
            classes={{ focused: classes.userFiledInputFocusedStyle }}
            inputProps={{ 'data-testid': 'password-input' }}
            type="password"
            disableUnderline
            placeholder="Enter password"
            onChange={e => handleCredentialsChange('password', e.target.value)}
          />
          <Button
            type="submit"
            value="Submit"
            className={classes.buttonStyle}
            data-testid="login-button"
            disabled={!loginCred.password || !loginCred.username}
            classes={{ disabled: classes.disableButtonStyle }}
          >
            Login
          </Button>
          <Grid item className={classes.formContainer}>
            <p className={classes.erroMessage}>{errorMessage}</p>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}
