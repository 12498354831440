import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '28em',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const SearchPrompt = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        data-testid="search-to-start"
        src="searchToStart.svg"
        alt="Search to start"
      />
    </div>
  );
};

export default SearchPrompt;
