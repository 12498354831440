import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import * as React from 'react';
import { Status } from 'types/Status';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

const labels: Record<string, string> = {};
labels[Status.SCANNED_IN_DEPOT] = 'Depot scan';
labels[Status.REBOOK] = 'Rebook';

const useStyles = makeStyles(() => ({
  container: {
    width: '90%',
    margin: '1em',
  },
  moreButton: {
    border: '1px solid #03A687',
    color: 'white',
    background: '#03A687',
    textTransform: 'none',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    '&:hover': {
      background: '#03A687',
    },
  },
  currentMode: {
    border: '1px solid #03A687',
    color: 'white',
    background: '#03A687',
    textTransform: 'none',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    width: '100%',
    '&:not(:last-child)': {
      borderRight: '1px solid #03A687',
    },
    '&:hover': {
      background: '#03A687',
    },
  },
}));

interface SortStatusChangerProps {
  mode: Status;
  onValueChange: (status: Status) => void;
  openByDefault: boolean;
}

const SortStatusChanger = ({
  mode,
  onValueChange,
  openByDefault,
}: SortStatusChangerProps) => {
  const selectedTabValue = mode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  const onTabSelected = (_event: React.ChangeEvent<{}>, status: Status) => {
    onValueChange(status);
    setOpen(false);
  };
  const classes = useStyles();

  const [open, setOpen] = React.useState(openByDefault || false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        disableElevation
        className={classes.container}
      >
        <Button size="large" className={classes.currentMode}>
          {labels[selectedTabValue]}
        </Button>
        <Button
          size="large"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.moreButton}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            in={TransitionProps && TransitionProps.in}
            onEnter={TransitionProps && TransitionProps.onEnter}
            onExited={TransitionProps && TransitionProps.onExited}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    selected={selectedTabValue === Status.SCANNED_IN_DEPOT}
                    onClick={event =>
                      onTabSelected(event, Status.SCANNED_IN_DEPOT)
                    }
                    aria-label={`Switch to ${labels[Status.SCANNED_IN_DEPOT]}`}
                  >
                    {labels[Status.SCANNED_IN_DEPOT]}
                  </MenuItem>
                  <MenuItem
                    selected={selectedTabValue === Status.REBOOK}
                    onClick={event => onTabSelected(event, Status.REBOOK)}
                    aria-label={`Switch to ${labels[Status.REBOOK]}`}
                  >
                    {labels[Status.REBOOK]}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SortStatusChanger;
