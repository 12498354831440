import * as React from 'react';
import { ReactElement, useContext, useRef, useState } from 'react';
import LabelNumberInput from 'components/LabelNumberInput';
import {
  fetchSortationData,
  NetworkErrorMessage,
  StatusResponse,
  SuccessResponse,
} from 'api/fetchSortationData';
import CustomAppBar from 'components/CustomAppBar/CustomAppBar';
import { playFailureSound, playSuccessSound } from 'util/sound';
import SearchPrompt from 'components/SortationDataDisplay/SearchPrompt';
import { makeStyles } from '@material-ui/core';
import { Status } from 'types/Status';
import ScanResponse from 'components/ScanResponse/ScanResponse';
import ResultLoader from 'components/ResultLoader/ResultLoader';
import SortationResultDisplay from 'components/SortationDataDisplay/SortationResultDisplay';
import { useAuthorization } from '../../auth/AuthorizationProvider';
import SortStatusChanger from '../../components/SortStatusChanger/SortStatusChanger';
import { scrollToRef } from '../../util/scrollToRef';
import { BOLContext } from '../../contexts/BOLContext';
import { getWarehouses } from '../../util/warehouse';
import { ErrorResponse } from '../../api/shared';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#FAFAFA',
    width: '100% !important',
    minHeight: '100vh',
  },
  contentContainer: {
    width: '90%',
    marginTop: 16,
    marginBottom: 16,
  },
}));

export interface SortPageProps {
  defaultLoading?: boolean;
  defaultSortationData?: Partial<SuccessResponse>;
  defaultSubmittedLabelNumber?: string;
  defaultSelectedSortStatus?: Status;
  defaultSortationError?: null | string;
  defaultInitialSearch?: boolean;
}

const defaultProps: SortPageProps = {
  defaultLoading: false,
  defaultSortationData: {},
  defaultSubmittedLabelNumber: '',
  defaultSelectedSortStatus: Status.SCANNED_IN_DEPOT,
  defaultSortationError: null,
  defaultInitialSearch: true,
};

const isStatusResponse = (
  args: SuccessResponse | Partial<SuccessResponse> | StatusResponse
): args is StatusResponse => !!(args as StatusResponse).message;

const isSuccessResponse = (
  args: SuccessResponse | Partial<SuccessResponse> | StatusResponse
): args is SuccessResponse => !isStatusResponse(args);

const SortPage: React.FunctionComponent<SortPageProps> = ({
  // eslint-disable-next-line max-len
  defaultLoading,
  defaultSortationData,
  defaultSubmittedLabelNumber,
  defaultSelectedSortStatus,
  defaultSortationError,
  defaultInitialSearch,
}: SortPageProps) => {
  // eslint-disable-next-line max-len
  const [sortationData, setSortationData] = useState<
    SuccessResponse | Partial<SuccessResponse> | StatusResponse
  >(defaultSortationData || {});
  const [submittedLabelNumber, setSubmittedLabelNumber] = useState<string>(
    defaultSubmittedLabelNumber || ''
  );
  // eslint-disable-next-line max-len
  const [selectedSortStatus, setSelectedSortStatus] = useState<Status>(
    defaultSelectedSortStatus || Status.SCANNED_IN_DEPOT
  );
  // eslint-disable-next-line max-len
  const [sortationError, setSortationError] = useState<null | string>(
    defaultSortationError || null
  );
  // eslint-disable-next-line max-len
  const [initialSearch, setInitialSearch] = useState<boolean>(
    defaultInitialSearch === undefined ? true : defaultInitialSearch
  );
  const [loading, setLoading] = useState<boolean>(defaultLoading || false);

  const { authorizationToken, sessionExpired, currentWarehouse } =
    useAuthorization();
  const { fetchBOL } = useContext(BOLContext);

  const labelNumberInputBlockRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const onSortStatusChange = (selectedStatusValue: Status) => {
    setSelectedSortStatus(selectedStatusValue);
    setSortationError(null);
    setSortationData({});
    setInitialSearch(true);
    setSubmittedLabelNumber('');
    setLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.getElementById('label-number-input')?.focus();
  };

  const onFormSubmit = async (event: any, labelNumber: string) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await fetchSortationData(
        selectedSortStatus,
        labelNumber,
        authorizationToken
      );

      const resultError = result as ErrorResponse;
      const resultSuccess = result as SuccessResponse | StatusResponse;

      if (resultError.error) {
        if (
          [400, 401, 403].includes(resultError.statusCode || 0) ||
          resultError.message === NetworkErrorMessage
        ) {
          sessionExpired();
          window.location.reload();
        }
        setSubmittedLabelNumber(labelNumber);
        playFailureSound();
        setSortationData({});
        setSortationError(resultError.message);
        scrollToRef(labelNumberInputBlockRef);
      } else {
        setSubmittedLabelNumber(labelNumber);
        playSuccessSound();
        setSortationData(resultSuccess);
        setSortationError(null);
        scrollToRef(contentRef);
      }
    } catch (error: any) {
      setSubmittedLabelNumber(labelNumber);
      playFailureSound();
      setSortationData({});
      setSortationError(error.message);
      scrollToRef(labelNumberInputBlockRef);
    } finally {
      setLoading(false);
      setInitialSearch(false);
      setSelectedSortStatus(Status.SCANNED_IN_DEPOT);
    }
  };

  // eslint-disable-next-line max-len
  const handleBOL = (warehouseTo: string) =>
    fetchBOL(currentWarehouse, warehouseTo, new Date(), authorizationToken);

  // eslint-disable-next-line consistent-return
  const renderContent = (): ReactElement | undefined => {
    if (loading) {
      return <ResultLoader hardBlock />;
    }
    if (initialSearch) {
      return <SearchPrompt />;
    }

    if (sortationError || !isSuccessResponse(sortationData)) {
      return (
        <ScanResponse
          success={false}
          labelNumber={submittedLabelNumber}
          error={sortationError || undefined}
        />
      );
    }

    if (selectedSortStatus !== Status.SCANNED_IN_DEPOT) {
      return <ScanResponse success labelNumber={submittedLabelNumber} />;
    }

    return (
      <SortationResultDisplay
        sortationData={sortationData}
        labelNumber={submittedLabelNumber}
      />
    );
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CustomAppBar warehousesList={getWarehouses()} handleBOL={handleBOL} />
      <div style={{ display: 'none' }}>
        <SortStatusChanger
          onValueChange={onSortStatusChange}
          mode={selectedSortStatus}
          openByDefault={false}
        />
      </div>
      <div ref={labelNumberInputBlockRef} />
      <LabelNumberInput
        onFormSubmit={(event: any, labelNumber: string) =>
          onFormSubmit(event, labelNumber)
        }
        locked={loading}
      />
      <div className={classes.contentContainer} ref={contentRef}>
        {renderContent()}
      </div>
    </div>
  );
};

SortPage.defaultProps = defaultProps;

export default SortPage;
