import { makeStyles, styled, Grid, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import ResultLoader from 'components/ResultLoader/ResultLoader';
import CustomAppBar from '../../components/CustomAppBar/CustomAppBar';
import { fetchBOL } from '../../api/fetchBOL';
import { useAuthorization } from '../../auth/AuthorizationProvider';
import { getWarehouses } from '../../util/warehouse';
import { CloseManifest, ListManifests, Manifest } from '../../api/manifests';
import { formatDate } from '../../util/date';
import { ErrorResponse } from '../../api/shared';

export interface ManifestsPageProps {
  defaultFilter?: string;
  listManifests: ListManifests;
  closeManifest: CloseManifest;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#FAFAFA',
    width: '100% !important',
    minHeight: '100vh',
  },
  containerElement: {
    margin: '0 1em',
  },
  gridItem: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: '1px 0',
  },
  gridKey: {
    fontWeight: 'bold',
  },
  actionsBlock: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  gridItemItem: {
    padding: '6px 8px',
    lineHeight: '1.75',
  },
}));

const formatCounts = ({ pallets, shipments }: Manifest['contains']): string => {
  const results = [];
  if (pallets && pallets > 0) {
    results.push(`${pallets.toLocaleString()} Pallets`);
  }
  if (shipments && shipments > 0) {
    results.push(`${shipments.toLocaleString()} Shipments`);
  }
  return results.join(', ');
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default function ManifestsPage({
  defaultFilter,
  listManifests,
  closeManifest,
}: ManifestsPageProps) {
  const classes = useStyles();

  const { authorizationToken, currentWarehouse, sessionExpired } =
    useAuthorization();

  const handleBOL = (warehouseTo: string) =>
    fetchBOL(currentWarehouse, warehouseTo, new Date(), authorizationToken);

  const [filter, setFilter] = useState<string>(defaultFilter || 'open');
  const [manifests, setManifests] = useState<Manifest[]>([]);
  const [touched, touch] = useState<Date>(new Date());
  const [loadingCnt, setLoadingCnt] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const loadingElements = useMemo(() => {
    return {
      inc: () => setLoadingCnt(c => c + 1),
      dec: () => setLoadingCnt(c => c - 1),
      count: () => loadingCnt,
    };
  }, [loadingCnt, setLoadingCnt]);

  const handleFilterChange = async (_: any, newFilter: string) => {
    setFilter(newFilter || filter);
  };

  const handleCloseClickBuilder = (manifest: Manifest) => {
    return async () => {
      loadingElements.inc();
      try {
        await closeManifest(manifest.manifestNumber, authorizationToken);
        touch(new Date());
      } catch (e) {
        setErrorMessage(e.message);
      }
      loadingElements.dec();
    };
  };

  useEffect(() => {
    (async () => {
      loadingElements.inc();
      try {
        const listManifestsResponse = await listManifests({
          status: filter,
          depotCode: currentWarehouse,
          authorizationToken,
        });
        const listManifestsResponseError =
          listManifestsResponse as ErrorResponse;
        const listManifestsResponseSuccess =
          listManifestsResponse as Manifest[];
        if (listManifestsResponseError.error) {
          if ([401, 403].includes(listManifestsResponseError.statusCode || 0)) {
            sessionExpired();
            window.location.reload();
          } else {
            setErrorMessage(listManifestsResponseError.message);
          }
        } else {
          setManifests(listManifestsResponseSuccess);
        }
      } catch (e) {
        setErrorMessage(e.message);
      }

      loadingElements.dec();
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    filter,
    listManifests,
    currentWarehouse,
    authorizationToken,
    touched,
    sessionExpired,
  ]);

  const renderContent = (): ReactElement | undefined => {
    if (loadingElements.count() > 0) {
      return <ResultLoader hardBlock />;
    }
    if (errorMessage !== null) {
      return (
        <section className={classes.containerElement}>
          <h2>Error</h2>
          <p>{errorMessage}</p>
          <p>Try to reload page</p>
        </section>
      );
    }
    return (
      <>
        <section className={classes.containerElement}>
          <StyledToggleButtonGroup
            value={filter}
            exclusive
            onChange={handleFilterChange}
            aria-label="Filter"
            color="default"
            size="small"
          >
            <ToggleButton
              value="open"
              aria-label="open"
              data-testid="filter-open"
            >
              Open
            </ToggleButton>
            <ToggleButton
              value="closed"
              aria-label="closed"
              data-testid="filter-closed"
            >
              Closed
            </ToggleButton>
          </StyledToggleButtonGroup>
        </section>
        <section className={classes.containerElement}>
          <Grid container spacing={0}>
            {manifests.map(manifest => (
              <Grid
                container
                item
                xs={12}
                spacing={1}
                className={classes.gridItem}
                key={manifest.manifestNumber}
              >
                <Grid item xs={12} sm={6}>
                  <div
                    className={[classes.gridItemItem, classes.gridKey].join(
                      ' '
                    )}
                  >
                    {manifest.manifestNumber}
                  </div>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <div className={classes.gridItemItem}>
                    {manifest.courierCode}
                  </div>
                </Grid>
                <Grid item xs={8} sm={3}>
                  <div className={classes.gridItemItem}>
                    {formatCounts(manifest.contains)}
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.actionsBlock}>
                  {manifest.closedAt ? (
                    <div className={classes.gridItemItem}>
                      {formatDate(manifest.closedAt)}
                    </div>
                  ) : (
                    <Button
                      onClick={handleCloseClickBuilder(manifest)}
                      data-testid="button-close"
                    >
                      Close
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </section>
      </>
    );
  };

  return (
    <div className={classes.container}>
      <CustomAppBar warehousesList={getWarehouses()} handleBOL={handleBOL} />
      <section className={classes.containerElement}>
        <h1>Manifests</h1>
      </section>
      {renderContent()}
      <div style={{ margin: '1em' }} />
    </div>
  );
}
