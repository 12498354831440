import OverlabelledDisplay from 'components/OverlabelledDisplay/OverlabelledDisplay';
import ScanResponse from 'components/ScanResponse/ScanResponse';
import { TransitResponse } from 'components/ScanResponse/TransitResponse';
import { isCurrentWarehouse } from 'util/warehouse';
import { SuccessResponse } from 'api/fetchSortationData';
import { useAuthorization } from 'auth/AuthorizationProvider';
import ShipmentSortationDisplay from './ShipmentSortationDisplay';
import PalletSortationDisplay from './PalletSortationDisplay';

export interface ShipmentSortationProps {
  sortationData: SuccessResponse;
  labelNumber: string;
}

const DisplayShipment = ({
  sortationData,
  labelNumber,
}: ShipmentSortationProps) => {
  const { currentWarehouse } = useAuthorization();

  if (
    sortationData.overlabelledWith !== undefined &&
    sortationData.labelURL !== undefined
  ) {
    return (
      <OverlabelledDisplay
        labelNumber={labelNumber}
        overlabelledWith={sortationData.overlabelledWith}
        labelURL={sortationData.labelURL}
      />
    );
  }
  if (
    sortationData.shipmentActivity &&
    !['delivery', 'pickup'].includes(sortationData.shipmentActivity)
  ) {
    return (
      <ScanResponse
        success={false}
        labelNumber={labelNumber}
        error={sortationData.shipmentActivity}
      />
    );
  }
  if (
    sortationData.nextScanDisplay !== undefined &&
    sortationData.nextScanDisplay !== null
  ) {
    return (
      <ScanResponse
        success={false}
        labelNumber={labelNumber}
        messageOverride={sortationData.nextScanDisplay}
      />
    );
  }
  if (sortationData.zone !== 'BigW') {
    if (!isCurrentWarehouse(sortationData.depotCode, currentWarehouse)) {
      return (
        <TransitResponse
          depotCode={sortationData.depotCode || ''}
          labelNumber={labelNumber}
        />
      );
    }
  }
  return (
    <ShipmentSortationDisplay
      labelNumber={labelNumber}
      zone={sortationData.zone}
      courierCode={sortationData.courierCode}
      sortCode={sortationData.sortCode}
      zoneRun={sortationData.zoneRun}
      pallet={sortationData.pallet}
      manifest={sortationData.manifest}
    />
  );
};

const DisplayPallet = ({
  sortationData,
  labelNumber,
}: ShipmentSortationProps) => {
  return (
    <PalletSortationDisplay
      labelNumber={labelNumber}
      zone={sortationData.zone}
      courierCode={sortationData.courierCode}
      sortCode={sortationData.sortCode}
      zoneRun={sortationData.zoneRun}
      pallet={sortationData.pallet}
      manifest={sortationData.manifest}
    />
  );
};

const SortationResultDisplay = ({
  sortationData,
  labelNumber,
}: ShipmentSortationProps) => {
  if (sortationData.type === 'pallet') {
    return DisplayPallet({ sortationData, labelNumber });
  }
  return DisplayShipment({ sortationData, labelNumber });
};

export default SortationResultDisplay;
