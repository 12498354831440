import axios from 'axios';
import { endpoint } from '../constants/endpoints';

const allowedOrigin =
  process.env.REACT_APP_ORIGIN_HEADER || 'https://gateway.lynedirect.com/sort';

const buildHeaders = (authorizationToken: string) => ({
  origin: allowedOrigin,
  Authorization: `Bearer ${authorizationToken}`,
});

export const closePallet = async (
  labelNumber: string,
  authorizationToken: string
): Promise<boolean> => {
  const headers = buildHeaders(authorizationToken);
  try {
    const result = await axios.put(
      `${endpoint.PALLETS}-close`,
      {},
      {
        headers,
        params: {
          labelNumber,
        },
      }
    );
    return result.status === 200 && result.data.isClosed;
  } catch (error) {
    console.dir(error);
    if (error.message !== 'Network Error') {
      throw Error(JSON.stringify(error.response));
    } else {
      throw Error(JSON.stringify({ data: { message: 'Unknown error' } }));
    }
  }
};

export const downloadPallet = async (
  labelNumber: string,
  authorizationToken: string
): Promise<string> => {
  const headers = buildHeaders(authorizationToken);
  try {
    const result = await axios.get(
      `${endpoint.PALLETS}-label`,

      {
        headers,
        params: {
          labelNumber,
        },
      }
    );
    return result.data.pdfBase64;
  } catch (error) {
    console.dir(error);
    if (error.message !== 'Network Error') {
      throw Error(JSON.stringify(error.response));
    } else {
      throw Error(JSON.stringify({ data: { message: 'Unknown error' } }));
    }
  }
};
