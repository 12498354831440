import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { CheckCircle, Label as LabelIcon } from '@material-ui/icons';
import { ScalableText } from '../ScalableText';
import { closePallet, downloadPallet } from '../../api/pallets';
import { useAuthorization } from '../../auth/AuthorizationProvider';
import Label from './Label';

export interface PalletProps {
  pallet: {
    labelNumber: string;
    isClosed: boolean;
    closedAt?: string;
  };
}

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  buttonIcon: {
    paddingRight: '0.25em',
  },
}));

const PalletControl = ({ pallet }: PalletProps) => {
  const classes = useStyles();
  const { authorizationToken, sessionExpired } = useAuthorization();
  const [isClosed, setIsClosed] = useState<boolean>(pallet.isClosed);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const closedAt = pallet.closedAt
    ? new Date(pallet.closedAt).toLocaleString()
    : undefined;

  const handleClosePalletClicked = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirm: boolean) => {
    confirm && (await handlePalletClose());
    setDialogOpen(false);
  };

  const handlePalletClose = async () => {
    if (pallet === undefined) {
      return;
    }
    try {
      setIsClosed(await closePallet(pallet.labelNumber, authorizationToken));
    } catch (error) {
      sessionExpired();
      window.location.reload();
    }
  };

  const handlePalletDownload = async () => {
    if (pallet === undefined) {
      return;
    }
    try {
      const base64 = await downloadPallet(
        pallet.labelNumber,
        authorizationToken
      );
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${base64}`;
      link.setAttribute('download', `pallet-${pallet.labelNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      // @ts-ignore
      link.parentNode.removeChild(link);
    } catch (error) {
      sessionExpired();
      window.location.reload();
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <ScalableText>{pallet.labelNumber}</ScalableText>
      </Grid>
      <Grid item xs={12}>
        <Label text="Pallet label number" />
      </Grid>

      <Grid item xs={12}>
        {closedAt && <Label text={`Closed at: ${closedAt}`} />}
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          size="large"
        >
          {isClosed ? (
            <>
              <Button onClick={handlePalletDownload}>
                <LabelIcon className={classes.buttonIcon} />
                Get Label
              </Button>
              <Button disabled>
                <CheckCircle className={classes.buttonIcon} />
                Pallet Closed
              </Button>
            </>
          ) : (
            <>
              <Button disabled>
                <LabelIcon className={classes.buttonIcon} />
                Get Label
              </Button>
              <Button onClick={handleClosePalletClicked}>
                <CheckCircle className={classes.buttonIcon} /> Close Pallet
              </Button>
            </>
          )}
        </ButtonGroup>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Close Pallet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to close pallet {pallet.labelNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
          <Button onClick={() => handleDialogClose(true)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PalletControl;
